import './style.css';
import Map from 'ol/Map';
import View from 'ol/View';

import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM, {ATTRIBUTION} from 'ol/source/OSM';

import Feature from 'ol/Feature';
import {
	Point,
	LineString } from 'ol/geom';

import {
	Icon, 
	Style,
	Circle as CircleStyle,
	Fill,
	RegularShape,
	Stroke
	} from 'ol/style';

import {
	fromLonLat,
	get
	} from 'ol/proj';
	
import {ScaleLine, defaults as defaultControls} from 'ol/control';

// default zoom, center
let zoom = 2;
let center = [0, 0];
let track = [];

// Limit multi-world panning to one world east and west of the real world.
// Geometry coordinates have to be within that range.
const extent = get('EPSG:3857').getExtent().slice();
extent[0] += extent[0];
extent[2] += extent[2];

// find params in the URL
var query = window.location.search.substring(1);
var vars = query.split("&");
for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    switch (pair[0]) {
    	case "z" : zoom = pair[1]; break;
    	case "c" : var c = pair[1].split('/'); center = fromLonLat([parseFloat(c[1]), parseFloat(c[0])]); break;
    	case "t" : 	var t = pair[1].split('/');
    			for (var i=1;i<t.length;i+=2) {
    				track.push(fromLonLat([parseFloat(t[i]), parseFloat(t[i-1])]));
    			}
    			break;
    }
} 

// build drawings for vector layer
const myfeatures = new Array();

// start point
if (track.length > 0) { 
	const start = new Feature({
		geometry: new Point(track[0]),
	});
	start.setStyle(
		new Style({
			image: new Icon({
				anchor: [0.5,0.95],
				crossOrigin: 'anonymous',
				src: 'data/mapPinGreen2.png',
			}),
		})
	);
	myfeatures.push(start);
}

// track path
if (track.length > 1) { 
	const path = new Feature({
		geometry: new LineString(track),
	});
	path.setStyle(
		new Style({
			stroke: new Stroke({
      				color: 'rgba(0, 0, 255, .7)',
     				width: 5,
    			}),
		})
	);
	myfeatures.push(path);
}

// finish point
if (track.length > 1) { 
	const finish = new Feature({
		geometry: new Point(track[track.length-1]),
	});
	finish.setStyle(
		new Style({
			image: new Icon({
				anchor: [0.5,0.95],
				crossOrigin: 'anonymous',
				src: 'data/mapPinRed2.png',
			}),
		})
	);
	myfeatures.push(finish);
}



/* -------- */

const vectorSource = new VectorSource({
	features: myfeatures,
});

const vectorLayer = new VectorLayer({
	source: vectorSource,
});

const raster = new TileLayer({
  source: new OSM({
    attributions: [
      '<a href="https://carto.lepetitsite.com/tuto" target="_blank">carto.lepetitsite.com</a> | <a href="https://openlayers.org/" target="_blank">openlayers.org</a> | ',
      ATTRIBUTION,
    ],}),
});

const map = new Map({
  target: 'map',
  controls: defaultControls().extend([
  				new ScaleLine({units: 'metric'})
  				]),
  layers: [raster, vectorLayer],
  view: new View({
    center: center,
    zoom: zoom,
    extent: extent
  })
});
